import { createInstanceNoToken } from './config';

const BASE_URL = '/logger';

/**
 * Enum for log levels.
 * @readonly
 * @enum {string}
 */
export const LogLevel = {
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
  CRITICAL: 'critical',
};

/**
 * Logs an error message to the server.
 * @param {Object} params - The parameters for the error log.
 * @param {LogLevel} params.level - The level of the error.
 * @param {string} params.message - The error message.
 * @param {string} params.origin - The origin of the error (e.g., 'client', 'server').
 * @param {Object} params.data - Additional data to log.
 * @param {Object} params.extraData - Extra data to log.
 * @param {string} params.errorUrl - The URL where the error occurred.
 * @returns {Promise<Object>} The response from the server.
 */
export const createErrorLog = async ({
  level,
  message,
  origin,
  data = '',
  extraData = '',
  errorUrl = '',
}) => {
  const body = {
    level,
    message,
    origin,
    data,
    extraData,
    errorUrl,
  };
  const axios = createInstanceNoToken();
  const { data: response } = await axios.post(`${BASE_URL}/error`, body);
  return response;
};
