import { useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from './authContext';
import { getExpirationTime, getToken, handleRedirectLogout } from '../../utils';

export const AuthProvider = ({ children }) => {
  const timeoutToken = useRef(null);

  const initTimeoutToken = useCallback(() => {
    const token = getToken();
    if (!token) return;
    const expTime = getExpirationTime(token);
    clearInterval(timeoutToken);
    timeoutToken.current = setTimeout(() => {
      handleRedirectLogout();
    }, expTime);
  }, []);

  const value = useMemo(
    () => ({
      initTimeoutToken,
    }),
    [
      initTimeoutToken,
    ],
  );

  return (
    <AuthContext.Provider
      value={value}
    >
      { children }
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
