import {
  Box, Typography,
} from '@material-ui/core';
import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import jwtDecode from 'jwt-decode';

import useStyles from './useStyles';

import { StepperContext } from '../../../../context/stepper';
import fields from '../../../../data/inputsLogin.json';
import Input from '../../../../components/Input';
import { AlertDialog, Button, Loader } from '../../../../components';
import {
  storeAuth, getLogoAndImageTemplate,
  getTokenAndRedirection,
  logOutMain,
  AuthError,
  saveExtraDataAuth,
  getEmail,
  handleLogout,
} from '../../../../utils';
import * as authAPI from '../../../../api/auth';
import * as clientAPI from '../../../../api/clients';
import * as processAPI from '../../../../api/process';
import * as userAPI from '../../../../api/users';
import {
  getFlowTypeHandler,
} from '../../../../utils/tags';
import useLoading from '../../../../hooks/useLoading';
import { AuthContext } from '../../../../context/auth';
import ErrorMessage from '../../../../components/ErrorMessage';
import AlertSnack from '../../../../components/AlertSnack';
import useError from '../../../../hooks/useError';
import { COMPANY, LOGIN_PHONE_ID, LOGIN_ROUTE } from '../../../../constants';
import { getFlowByProcess } from '../../../../utils/api/process';
import { normalizeText } from '../../../../utils/commons';
import { storeProductData } from '../../../../utils/product';
import { ThemeContext } from '../../../../context/Theme/themeContext';
import { useProduct } from '../../../../hooks/useProduct';
import { InformationDialog } from '../../../../components/InformationDialog';
import { SendCodeDialog } from '../../../../components/SendCodeDialog';
import { getConfigs } from '../../../../utils/configs';
import { getStepsTagsByProcessId } from '../../../main/shared/Login';

const INITIAL_ERROR = {
  isError: false,
  errorMessage: 'La verificacionestamal',
};
export const Login = () => {
  const { initTimeoutToken } = useContext(AuthContext);
  const { initStepper } = useContext(StepperContext);
  const { loadTheme } = useContext(ThemeContext);
  const { products } = useProduct();
  const classes = useStyles();
  const { isLoading, startLoading, endLoading } = useLoading();
  const {
    isError,
    errorType,
    errorMessage,
    showError,
    hideError,
  } = useError();
  const [searchParams] = useSearchParams();
  const { product = '' } = useParams();

  const {
    logoUrl,
  } = useMemo(() => getLogoAndImageTemplate(product), []);

  const [formFields, setFormFields] = useState(fields);
  const [phoneValue, setPhoneValue] = useState('');
  const [idRoles, setIdRoles] = useState(['']);
  const [showModal, setShowModal] = useState(false);
  const [isErrorPhoneValue, setIsErrorPhoneValue] = useState(INITIAL_ERROR);
  const [showSendCodeDialog, setShowSendCodeDialog] = useState(false);
  const [showInformationDialog, setShowInformationDialog] = useState(false);
  const disable = useMemo(() => formFields.some((field) => !field.value), [formFields]);
  const navigate = useNavigate();

  const handleChange = (event, value) => {
    const { name } = event.target;
    // eslint-disable-next-line max-len
    const newFields = formFields.map((field) => (field.name === name ? { ...field, value } : field));
    setFormFields(newFields);
  };

  const loginToken = async (token) => {
    const { validator: { email = '' } = {} } = jwtDecode(token);
    const { user } = await authAPI.loginByToken(email, token);
    storeAuth(user);
    return user;
  };

  const onLogout = async () => {
    await logOutMain();
    await authAPI.logout();
  };

  const backPhone = () => {
    setShowSendCodeDialog(false);
  };

  const generateCode = async (user, phone) => {
    const { whatsappSended } = await userAPI.generatePhoneCode(user);
    setPhoneValue(phone);
    setShowSendCodeDialog(true);
    return whatsappSended;
  };

  const onSubmit = async (code) => {
    try {
      await onLogout();
      startLoading();
      const phone = formFields[0].value;
      const userObject = {
        code,
        model: 'client',
        roles: idRoles,
        phone: `+521${phone}`,
      };

      const { user } = await userAPI.loginByPhoneCode(userObject);
      storeAuth(user);
      const email = getEmail();
      saveExtraDataAuth({ token: user.token });
      const { _id: clientId } = await clientAPI
        .getClientByCompanyAndEmail(COMPANY, email);
      const processes = await processAPI.getProcessListCash(clientId);
      const lastProcess = processes[0];
      const flow = await getFlowByProcess(lastProcess._id);
      const normalizedName = normalizeText(flow.name);
      const {
        actualStep: { stepRoute },
      } = await getStepsTagsByProcessId(lastProcess._id, normalizedName);
      saveExtraDataAuth({ process: lastProcess._id, flow: flow._id });
      const currentProduct = lastProcess.product;
      storeProductData(currentProduct);
      await loadTheme(products[0]);
      endLoading();
      initTimeoutToken();
      navigate(stepRoute);
    } catch (e) {
      showError(e.message, e.name || 'Error trying to submit');
      endLoading();
    }
  };

  const loginByTokenFlow = async () => {
    try {
      const queryParams = getTokenAndRedirection(searchParams);
      if (!queryParams) return false;
      const { token, redirection, process } = queryParams;
      startLoading();
      await handleLogout(token);
      await loginToken(token);
      const { normalizedFlowName } = await getFlowTypeHandler(process);
      const stepsTags = await getStepsTagsByProcessId(process, normalizedFlowName);
      initStepper(stepsTags);
      saveExtraDataAuth({ process });
      await loadTheme(products[0]);
      initTimeoutToken();
      return navigate(redirection);
    } catch (e) {
      showError(e.message, e.name || 'Error trying to loginByTokenFlow');
      return false;
    }
  };

  const verifyCode = async (event, code) => {
    try {
      // validacion del  codigo
      event.preventDefault();
      startLoading();
      setShowSendCodeDialog(false);
      const phone = formFields[0].value;
      const correctPhone = `+521${phone}`;

      const { verified } = await userAPI.verifyPhoneCode(correctPhone, code);
      if (verified) {
        await onSubmit(code);
      } else {
        setShowSendCodeDialog(true);
        setIsErrorPhoneValue({
          isError: true,
          errorMessage: 'Error al verificar el numero',
        });
      }
      endLoading();
    } catch (e) {
      console.log({ e });
      showError(e.message, e.name || 'Error trying to submit');
      endLoading();
      if (e instanceof AuthError) setShowModal(true);
    }
  };

  const checkPhoneAlreadyExists = async (user) => {
    const existsResponse = await userAPI.verifyPhoneAlreadyExists(user);
    const { exists = false } = existsResponse;
    return exists;
  };

  const sendCode = async (event) => {
    event.preventDefault();
    startLoading();
    const phone = formFields[0].value;
    const userObject = {
      company: COMPANY,
      phone: `+521${phone}`,
      shouldCheckCompanyConfig: false,
      model: 'client',
    };

    const exists = await checkPhoneAlreadyExists(userObject);
    if (!exists) {
      setShowInformationDialog(true);
      endLoading();
      return;
    }
    await generateCode(userObject, phone);
    endLoading();
  };

  const fetchClientRolesId = async () => {
    const payloadConfig = await getConfigs(LOGIN_PHONE_ID);
    const { clientRoles } = payloadConfig;
    setIdRoles(clientRoles);
  };

  const fetchData = async () => {
    try {
      startLoading();
      await loginByTokenFlow();
      await fetchClientRolesId();
      endLoading();
    } catch (e) {
      showError(e.message, e.name || 'Error trying to fetch  Data');
      endLoading();
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <AlertSnack
        isOpen={isError}
        type="error"
        onClose={hideError}
      >
        <ErrorMessage
          errorType={errorType}
          errorMessage={errorMessage}
        />
      </AlertSnack>
      <AlertDialog
        onCancel={() => setShowModal(false)}
        onAccept={() => navigate(LOGIN_ROUTE)}
        title="Intentas registrate con un correo inexistente"
        showModal={showModal}
        onCancelText="Registrarse con otro correo"
        onAcceptText="OK"
      />
      <SendCodeDialog
        onCancel={() => setShowSendCodeDialog(false)}
        backPhone={backPhone}
        onSubmit={verifyCode}
        phone={phoneValue}
        showModal={showSendCodeDialog}
        error={isErrorPhoneValue}
        title="Verifica tu celular"
        useEditPhone
        buttonText="Continuar"
      />
      <InformationDialog
        showModal={showInformationDialog}
        onSubmit={() => setShowInformationDialog(false)}
        buttonTitle="OK"
        descripcion="El celular que estás ingresando con nosotros no tiene una cuenta asociada. Por favor ingresa a Chiplo.com y crea un proceso nuevo."
      />
      <Loader open={isLoading} />
      <Box
        component="form"
        className={classes.formContainer}
        onSubmit={sendCode}
        method="post"
        autoComplete="off"
      >
        <Box className={classes.boxImage}>
          <img
            className={classes.imgLogo}
            src={logoUrl}
            alt="imgLogoMega"
          />
        </Box>
        <Box className={classes.inputContainer}>
          {formFields.map((field) => (
            <Box
              className={classes.inputFieldContainer}
            >
              <Typography variant="h6" className={classes.labelInput}>
                {field.title}
              </Typography>
              <Input
                label={field.label}
                type={field.fieldType?.name}
                name={field.name}
                value={field.value || ''}
                required={Boolean(field?.config?.required)}
                disabled={field?.config?.disabled}
                hide={Boolean(field?.config?.hide)}
                isEmpty={field?.isEmpty}
                errorMessage={field?.errorMessage}
                defaultValue={field?.config?.defaultValue}
                onChange={handleChange}
                extraInputClassName={classes.customBorderInput}
              />
            </Box>
          ))}
        </Box>
        <Box className={classes.buttonContainer}>
          <Button
            disabled={disable}
            type="submit"
            className={classes.btnLogin}
            fullWidth
          >
            Entrar
          </Button>
        </Box>
      </Box>
      <Box />
    </>
  );
};
