/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as googleAnalytics from '../../../../../utils/googleAnalytics/events';
import { FormLayout } from '../../../layouts/FormLayout';
import { AlertDialog, GroupFields, Loader } from '../../../../../components';
import * as fieldValueAPI from '../../../../../api/fieldValue';

import useLoading from '../../../../../hooks/useLoading';
import useRoute from '../../../../../hooks/useRoute';
import useError from '../../../../../hooks/useError';

import {
  AuthError, createUpdateTagGeneral, getAuth, getEmail, storeAuth, storeStepData, getAgentId, getCurrentStep, saveExtraDataAuth,
} from '../../../../../utils';

import * as companyConfigsAPI from '../../../../../api/companyConfigs';
import * as unprotectedAPI from '../../../../../api/unprotected';
import * as clientsAPI from '../../../../../api/clients';
import * as processAPI from '../../../../../api/process';
import * as authAPI from '../../../../../api/auth';
import * as productAPI from '../../../../../api/product';
import * as userAPI from '../../../../../api/users';
import * as nodeAPI from '../../../../../api/node';

import useStyles from './useStyles';
import {
  COMPANY, COMPANY_TYPE, CREATE_ACCOUNT, CREATE_ACCOUNT_NAME,
  LOGIN_PHONE_ID, LOGIN_ROUTE, REJECTED, FINISH_TYPE_MATRIZ_VIVIENDA,
  PERFILAMIENTO_EXIST, PROFILING_COTIZATION, PROFILING_EMPTY_MATRIZ,
} from '../../../../../constants';
import {
  createTags, getFlowTypeHandler, updateCurrentPageOnLocalStep, updateLocalStepsByStep,
} from '../../../../../utils/tags';
import { StepperContext } from '../../../../../context/stepper';
import { AuthContext } from '../../../../../context/auth/authContext';
import { useProduct } from '../../../../../hooks/useProduct';
import { storeProductData } from '../../../../../utils/product';
import {
  getAforo, getClientHaveAgent, getClientPropertyStage, getEnganche, getImporte, getMensualidad, getMeses, getPropietyValue,
  getYear,
} from '../../../../../utils/credit';
import { convertirAMoneda } from '../../../../../utils/currency';
import { generateSharedFieldValues } from '../../../../../utils/clients';
import {
  getSharedFieldValuesDataByUserId,
  saveSharedFieldValuesData,
  updateSharedFieldValuesData,
} from '../../../../../api/sharedFieldValues';
import { SendCodeDialog } from '../../../../../components/SendCodeDialog';
import { SimpleInputDialog } from '../../../../../components/SimpleInputDialog';
import { TermsAndConditions } from '../../../../../components/TermsAndConditions';
import { InformationDialog } from '../../../../../components/InformationDialog';
import { nowDate } from '../../../../../components/Input/InputBaseDatePicker/formatter';
import { getConsultPageConfigByFlow } from '../../../../../utils/commons';
import { getConfigs } from '../../../../../utils/configs';

import { useMatrizProfilling } from '../../../hooks/useMatrizProfilling';
import { getFlowName } from '../../../../../utils/flows';

import {
  createLeadFromClientData, saveLeadSharedFieldValues, storeClientFieldsData, updateClientByAgentAssigned, updateClientDataByAgentAssigned,
} from '../../../shared/CreateClientAccount';
import { useFieldValues } from '../../../hooks/useFieldValues';

import { getFieldValuesInFieldsFormat } from '../../../utils/fields';
import { storeLeadData } from '../../../../../utils/leads';
import { updateLeadWithTagSteps } from '../../../../../utils/api/leads.utils';
import { sendMatrizPerfilamientoEmail } from '../../../../../api/matrizPerfilamiento';
import { useCreateAccount } from '../../../../../hooks/useCreateAccount';
import { getClientsFields } from '../../../../../utils/api/companyConfigs';
import { isValueAValidCharacter } from '../../../../../utils/fields';
import { completeTimelineTagByTagName } from '../../../../../api/timeline';
import { useLogger } from '../../../../../hooks/useLogger';
import { LogLevel } from '../../../../../api/logs';

const NORMAL_FINISH_TYPE = 'NORMAL';

const PASSWORD_CONFIRM = 'passwordConfirm';
const PASSWORD_EQUALS_VALDIATION = 'Las contraseñas no son iguales';
const PASSWORD = 'password';
const PHONE = 'phone';
const EMAIL = 'email';
const FIRSTNAME = 'firstName';
const LASTNAME = 'lastName';
const SECOND_NAME = 'secondName';
const SECOND_LASTNAME = 'secondLastName';

const PASSWORDS = [PASSWORD_CONFIRM, PASSWORD];

const INITIAL_ERROR = {
  isError: false,
  errorMessage: 'La verificacionestamal',
};
const INITIAL_SIMPLE_INPUT_DIALOG = {
  show: false,
  input: {},
  buttonTitle: 'Actualizar Número',
  title: 'Ingresa tu número de teléfono',
  error: {
    isError: false,
    errorMessage: '',
  },
};

const CreateClient = () => {
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');
  const [agentSection, setAgentSection] = useState(null);
  const [clientObject, setClientObject] = useState({});
  const [isErrorPhoneValue, setIsErrorPhoneValue] = useState(INITIAL_ERROR);
  const [showSendCodeDialog, setShowSendCodeDialog] = useState(false);
  const [simpleInputDialogData, setSimpleInputDialogData] = useState(INITIAL_SIMPLE_INPUT_DIALOG);
  const { isLoading, startLoading, endLoading } = useLoading();
  const { getDefaultProduct, getFlowType } = useProduct();
  const { strategy: createAccountStrategy } = useCreateAccount();
  const {
    getMatrizBody,
    getCatTasaFieldValues,
    getCreditPropertyFieldValues,
    getCorrectMatrizProductsResult,
    execMatrizProfilling,
    formatPerfilamientoFieldValue,
    sortByCat,
    updateClientConditionsValues,
  } = useMatrizProfilling({ company: COMPANY });
  const { storeLocalFieldValues, formatFromFieldValueModelToUpload } = useFieldValues({ company: COMPANY });
  const { logError } = useLogger();

  const defaultProduct = getDefaultProduct();

  const { initTimeoutToken } = useContext(AuthContext);
  const {
    initStepper,
    updateCurrentStep,
    updateStateStepper, state: { steps, current },
  } = useContext(StepperContext);

  const {
    navigateWithQueryParam,
    searchParams,
  } = useRoute();
  const [idRoles, setIdRoles] = useState(['']);

  const [showModal, setShowModal] = useState(false);
  const [sections, setSections] = useState([]);
  const [agentId, setAgentId] = useState(null);
  const [showInformationDialog, setShowInformationDialog] = useState(false);
  const [pageConfig, setPageConfig] = useState({
    title: '',
    agentSectionTitle: '',
    finishType: NORMAL_FINISH_TYPE,
    profillingFields: [],
    defaultProfillingFieldValues: [],
  });
  const [productId, setProductId] = useState(defaultProduct._id);
  const [flow, setFlow] = useState([]);

  const classes = useStyles();

  const handleChange = (event, value, position) => {
    const { name } = event.target;
    const newSections = sections.map((section, index) => {
      if (index !== position) return section;
      const newFields = section.fields.map((field) => (field.name === name ? { ...field, value, errorMessage: '' } : field));
      return { ...section, fields: newFields };
    });
    setSections(newSections);
  };

  const isNotCompleted = () => {
    if (!acceptTermsAndConditions) return true;
    return sections.some((section) => {
      const { fields = [] } = section;
      return fields.some(({ config = {}, value = '', status = {} }) => {
        const { value: statusValue } = status;
        const { required = false, hide = false } = config;
        if (!hide && !required && statusValue === REJECTED) return true;
        if (hide || !required) return false;
        return (!isValueAValidCharacter(value) || statusValue === REJECTED);
      });
    });
  };

  const setValueErrorToField = (theErrorMessage, fieldName) => {
    const newSections = sections.map((section) => {
      const newFields = section.fields.map(
        (field) => (field.name === fieldName ? { ...field, errorMessage: theErrorMessage } : field),
      );
      return { ...section, fields: newFields };
    });
    setSections(newSections);
  };
  const findFields = (fieldsNames) => sections.reduce((acc, curr) => {
    const { fields } = curr;
    const filterValues = fields.filter(({ name }) => fieldsNames.includes(name));
    return [...acc, ...filterValues];
  }, []);

  const validatePasswordConfirmation = () => {
    const foundPasswords = findFields(PASSWORDS);
    if (foundPasswords.length < 2) return false;
    if (!(foundPasswords[0].value === foundPasswords[1].value)) {
      setValueErrorToField(PASSWORD_EQUALS_VALDIATION, PASSWORD_CONFIRM);
      return false;
    }
    return true;
  };

  const getCorrectPhone = (phoneText, addPhonePrefix) => (addPhonePrefix ? `+521${phoneText}` : phoneText);

  const transformDataToUpload = ({ filterFields, addPhonePrefix = false, usePhoneValueFromFields = false }) => {
    const newFields = sections.reduce((acc, curr) => {
      const { fields } = curr;
      return [...acc, ...fields];
    }, []).filter(({ name }) => !filterFields.includes(name)).map(({
      _id, config, value, fieldCatalog, name: fieldName,
    }) => {
      const correctPhoneValue = usePhoneValueFromFields ? value : phoneValue;
      return {
        _id,
        config,
        value: fieldName === PHONE ? getCorrectPhone(correctPhoneValue, addPhonePrefix) : value,
        fieldCatalog: {
          _id: fieldCatalog?._id || '',
        },
      };
    });
    return {
      company: COMPANY,
      fields: newFields,
    };
  };

  const createProcess = async (client, token) => {
    const dataToUpload = {
      flow,
      client,
      fields: [],
      product: productId,
    };
    return processAPI.createProcess(dataToUpload, COMPANY, token);
  };

  const saveExtraDataInStorage = (extraData) => {
    const authData = getAuth();
    storeAuth({
      ...authData,
      ...extraData,
    });
  };

  const saveCreditValues = async (process, token) => {
    const importe = getImporte();
    const meses = getMeses();
    const aforo = getAforo();
    const propietyValue = getPropietyValue();
    const monthlyPaid = getMensualidad();
    const enganche = getEnganche();
    const year = getYear();
    const clientPropertyStage = getClientPropertyStage();
    const clientHaveAgent = getClientHaveAgent();
    await Promise.all([
      fieldValueAPI.saveFieldValue({
        field: '6515ede55ab415f23cb38c55',
        value: clientPropertyStage,
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '65396fb7a97ccfb0db57040a',
        value: clientHaveAgent,
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '6515df2e5ab415f23cb0db85',
        value: convertirAMoneda(enganche),
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '6515df5f5ab415f23cb0df1c',
        value: `${year} años`,
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '64dd5880067071a24e5dfa4a',
        value: convertirAMoneda(propietyValue),
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '64e3bfa365dc58ed7b91ab1c',
        value: convertirAMoneda(monthlyPaid),
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '64b71f8acbf8225d4573b07d',
        value: convertirAMoneda(importe),
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '64b71fa1cbf8225d4573b07f',
        value: `${meses} meses`,
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '64dbe1e7067071a24e3d83c2',
        value: `${aforo}`,
        process,
      }, token),
    ]);
  };

  const saveSharedFieldValues = async (fields, clientId, token) => {
    const dataSharedFieldValues = generateSharedFieldValues(fields, clientId);
    await Promise.all(
      dataSharedFieldValues.map(
        async (itemSharedFieldValue) => saveSharedFieldValuesData(itemSharedFieldValue, token),
      ),
    );
  };

  const generateCode = async (user, phone) => {
    await userAPI.generatePhoneCode(user);
    setPhoneValue(phone);
    setShowSendCodeDialog(true);
  };

  const checkPhoneAlreadyExists = async (user) => {
    const existsResponse = await userAPI.verifyPhoneAlreadyExists(user);
    const { exists = false } = existsResponse;
    return exists;
  };

  const getFullName = () => {
    const arrayFields = findFields([FIRSTNAME, SECOND_NAME, LASTNAME, SECOND_LASTNAME]);

    const fullName = arrayFields.reduce((acc, curr) => {
      const { value } = curr;
      return acc ? `${acc} ${value}` : `${value}`;
    }, '');
    return fullName;
  };

  const sendCode = async () => {
    if (isNotCompleted()) return;
    startLoading();
    const [{ value: phone }] = findFields([PHONE]);

    const userObject = {
      company: COMPANY,
      phone: `+521${phone}`,
      fullName: getFullName(),
      model: 'client',
    };
    const phoneExist = await checkPhoneAlreadyExists(userObject);
    if (phoneExist) {
      setShowInformationDialog(true);
      endLoading();
      return;
    }
    await generateCode(userObject, phone);
    endLoading();
  };

  const createClient = async (dataToUpload) => {
    const clientReponse = await clientsAPI.saveFieldsData(dataToUpload);
    const { client, exists } = clientReponse;
    setClientObject(client);
    return { client, exists };
  };

  const fetchAutoNodes = async (autoExecutableNodes, token) => {
    const autoNodes = await Promise.all(autoExecutableNodes.map((nodeAutoExe) => nodeAPI.getNodeById(nodeAutoExe, token)));
    return autoNodes;
  };

  const executeAutoNodes = async (processId, token) => {
    const { autonodes = [] } = pageConfig;
    if (!autonodes) return;
    const autoNodes = await fetchAutoNodes(autonodes, token);
    if (Array.isArray(autoNodes) && autoNodes.length) {
      for (const autoNode of autoNodes) {
        await nodeAPI.autoExecuteNode({ nodeConfig: autoNode, process: processId }, token);
      }
    }
  };

  const getValueFromFieldsByName = (fieldName) => {
    const [foundField] = findFields([fieldName]);
    const { value = '' } = foundField;
    return value;
  };

  const normalFinish = async (client, token) => {
    const { process } = await createProcess(client, token);
    await saveCreditValues(process._id, token);
    const { normalizedFlowName } = await getFlowTypeHandler(process._id, token);
    const newTags = await createTags(process._id, normalizedFlowName, CREATE_ACCOUNT_NAME, token);
    await executeAutoNodes(process._id, token);
    const newProcess = { ...process, tags: newTags };
    await updateClientDataByAgentAssigned({
      client, agentId, process: newProcess, token,
    });
    updateStateStepper(true);
    saveExtraDataInStorage({ process: process._id, flow, token });
    createAccountStrategy.createTagLayer({ clientId: client._id });
    await completeTimelineTagByTagName(process._id, COMPANY, { tagName: 'iniciatramite' });
    storeProductData(defaultProduct);
    initTimeoutToken();
  };

  const onPerfilamientoExists = async (leadData) => {
    const localStepPosition = getCurrentStep();
    const currentPage = PROFILING_COTIZATION;
    const nextStep = localStepPosition + 1;
    const { steps: localSteps, stepRoute } = updateLocalStepsByStep(localStepPosition);
    updateCurrentPageOnLocalStep(nextStep, currentPage);
    googleAnalytics.perfilamientoCrediticio();
    await updateLeadWithTagSteps(leadData);
    navigateWithQueryParam(localSteps[nextStep].route, 'inmobiliaria');
  };

  const onPerfilamientoEmpty = async (leadData) => {
    const currentPage = PROFILING_EMPTY_MATRIZ;
    const localStepPosition = getCurrentStep();
    const nextStep = localStepPosition + 1;
    const { steps: localSteps, stepRoute } = updateLocalStepsByStep(localStepPosition);
    updateCurrentPageOnLocalStep(nextStep, currentPage);
    googleAnalytics.perfilamientoCrediticio();
    await updateLeadWithTagSteps(leadData);
    navigateWithQueryParam(localSteps[nextStep].route, 'inmobiliaria');
  };

  const matrizExecFinish = async () => {
    const newClientData = transformDataToUpload({ filterFields: [PASSWORD_CONFIRM], addPhonePrefix: true, usePhoneValueFromFields: true });
    const { fields: clientFields } = newClientData;
    const { fields: sharedFieldValues } = transformDataToUpload({ filterFields: [PASSWORD_CONFIRM], usePhoneValueFromFields: true });

    const inmobiliaria = searchParams.get('inmobiliaria');
    const createdLead = await createLeadFromClientData(clientFields, COMPANY, inmobiliaria);
    const { _id: leadId } = createdLead;

    const matrizBody = await getMatrizBody();
    const { fieldValues: matrizFieldValues } = matrizBody;
    const { values, matrizStatus, newConditions } = await execMatrizProfilling(matrizBody);
    storeLeadData(createdLead);

    const perfilamientoFieldValue = formatPerfilamientoFieldValue(values);
    const formattedFieldValues = formatFromFieldValueModelToUpload(matrizFieldValues, true);
    const creditInfoFieldValues = getCreditPropertyFieldValues();
    const leadFieldValuesToSave = [...formattedFieldValues, ...creditInfoFieldValues, perfilamientoFieldValue];

    const fieldValuesFormatted = getFieldValuesInFieldsFormat(leadFieldValuesToSave);
    await saveLeadSharedFieldValues([...fieldValuesFormatted, ...sharedFieldValues], leadId);
    if (newConditions) {
      await updateClientConditionsValues(newConditions);
    }
    storeClientFieldsData(newClientData, sharedFieldValues);
    googleAnalytics.matrizPerfilamiento(matrizStatus);
    if (matrizStatus === PERFILAMIENTO_EXIST && values) {
      const catAndTasasFieldValues = await getCatTasaFieldValues(values);
      const catFieldsFormatted = getFieldValuesInFieldsFormat(catAndTasasFieldValues);
      await saveLeadSharedFieldValues(catFieldsFormatted, leadId);
      const { products: productsMatriz } = getCorrectMatrizProductsResult(values);
      const sortedProducs = sortByCat(productsMatriz);
      await sendMatrizPerfilamientoEmail({ leadId, company: COMPANY, body: sortedProducs });
      storeLocalFieldValues([...leadFieldValuesToSave, ...catAndTasasFieldValues]);
      storeClientFieldsData(newClientData, sharedFieldValues);
      await onPerfilamientoExists(createdLead);
    } else {
      await onPerfilamientoEmpty(createdLead);
    }
  };

  const execFinishByType = async () => {
    switch (pageConfig.finishType) {
      case FINISH_TYPE_MATRIZ_VIVIENDA:
        return matrizExecFinish();
      case NORMAL_FINISH_TYPE:
      default:
        return sendCode();
    }
  };

  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      startLoading();
      await execFinishByType();
    } catch (e) {
      endLoading();
      if (e instanceof AuthError) {
        setShowModal(true);
        return;
      }
      await logError({
        message: e.message,
        error: e,
        level: LogLevel.CRITICAL,
        origin: 'CreateClient - onSubmit',
      });
    } finally {
      endLoading();
    }
  };

  const verifyCode = async (event, code) => {
    try {
      startLoading();
      setShowSendCodeDialog(false);
      const newClientData = transformDataToUpload({ filterFields: [PASSWORD_CONFIRM], addPhonePrefix: true });
      const dataToUpload = transformDataToUpload({ filterFields: [PASSWORD_CONFIRM] });
      const correctPhone = `+521${phoneValue}`;
      const email = getValueFromFieldsByName(EMAIL);

      const userObject = {
        phone: correctPhone,
        code,
        model: 'client',
        roles: idRoles,
        email,
      };

      const { verified } = await userAPI.verifyPhoneCode(correctPhone, code);
      if (verified) {
        const { client, exists } = await createClient(newClientData);
        const { _id } = client;
        if (exists) {
          endLoading();
          showModal(true);
          return;
        }

        const userResponse = await userAPI.loginByPhoneCode(userObject);
        const { user } = userResponse;
        const { token } = user;
        const { fields } = dataToUpload;

        await saveSharedFieldValues(fields, _id, token);
        googleAnalytics.crearCuenta();
        storeAuth(user);
        await normalFinish(client, token);
      } else {
        setShowSendCodeDialog(true);
        setIsErrorPhoneValue({
          isError: true,
          errorMessage: 'Error al verificar el numero',
        });
      }
      endLoading();
    } catch (e) {
      if (e instanceof AuthError) {
        setShowModal(true);
        endLoading();
        return;
      }
      if (e.response) {
        if (e.response.status === 422) {
          setShowInformationDialog(true);
        } else if (e.response.status === 409) {
          setShowModal(true);
        } else {
          await logError({
            message: 'Error:',
            error: e,
            level: LogLevel.CRITICAL,
            origin: 'CreateClient - verifyCode',
          });
        }
      } else if (e.message) {
        await logError({
          message: e.message,
          error: e,
          level: LogLevel.CRITICAL,
          origin: 'CreateClient - verifyCode',
        });
      } else {
        await logError({
          message: 'Error inesperado',
          error: e,
          level: LogLevel.CRITICAL,
          origin: 'CreateClient - verifyCode',
        });
      }
      endLoading();
    } finally {
      endLoading();
    }
  };

  const getFieldsData = (fieldsIds) => fieldsIds.map((fieldId) => unprotectedAPI.getById(fieldId));

  const setDefaultValues = (fields) => fields.map((field) => {
    const { value = '', config = {} } = field;
    return !value && config?.defaultValue
      ? { ...field, value: config?.defaultValue }
      : { ...field, value };
  });

  const getFieldsStructure = async (payload) => {
    const fieldsData = await Promise.all(getFieldsData(payload.fields));
    const fieldsDataWithValues = setDefaultValues(fieldsData);

    const fieldsSubtitleData = await Promise.all(getFieldsData(payload.fieldsSubtitle));
    const fieldsSubtitleDataWithValues = setDefaultValues(fieldsSubtitleData);
    return [{
      fields: fieldsDataWithValues,
      title: payload.title,
    },
    {
      fields: fieldsSubtitleDataWithValues,
      title: payload.subtitle,
    },
    ];
  };

  const generateAgentSection = async (_agentData, _pageConfig) => {
    const { fullName } = _agentData;
    const { agentField: idAgentField, agentSectionTitle } = _pageConfig;
    const agentField = await unprotectedAPI.getById(idAgentField);
    const newAgentField = { ...agentField, value: fullName };
    const newAgentSection = {
      title: agentSectionTitle,
      fields: [newAgentField],
    };
    setAgentSection(newAgentSection);
  };

  const fetchAgentData = async (_pageConfig) => {
    const agentIdStored = getAgentId();
    if (!agentIdStored) return;
    try {
      const agentDataResponse = await clientsAPI.getAgentData(agentIdStored);
      if (!agentDataResponse) return;
      await generateAgentSection(agentDataResponse, _pageConfig);
      setAgentId(agentIdStored);
    } catch (error) {
      console.log('N A');
    }
  };

  const fetchClientRolesId = async () => {
    const payloadConfig = await getConfigs(LOGIN_PHONE_ID);
    const { clientRoles } = payloadConfig;
    setIdRoles(clientRoles);
  };

  const fetchData = async () => {
    try {
      startLoading();
      const productAndFlow = await productAPI
        .getFlowAndProductByCompanyIdAndProductName(
          COMPANY,
          defaultProduct.name,
        );
      if (!productAndFlow) {
        endLoading();
        return;
      }
      setFlow(productAndFlow.flow);
      setProductId(productAndFlow.product);
      const flowName = getFlowName() || defaultProduct.flowName;
      const _pageConfig = await getConsultPageConfigByFlow(
        flowName,
        CREATE_ACCOUNT,
        CREATE_ACCOUNT,
      );

      const flowType = await getFlowType(flowName);
      const payload = await getClientsFields(flowType);
      const fieldsData = await getFieldsStructure(payload);
      await fetchClientRolesId();
      await fetchAgentData(_pageConfig);
      setPageConfig(_pageConfig);
      setSections(fieldsData);
      endLoading();
    } catch (e) {
      await logError({
        message: 'Error al cargar los datos de perfilamiento',
        error: e,
        level: LogLevel.CRITICAL,
        origin: 'CreateClient - fetchData',
      });
      endLoading();
    }
  };

  const updateSharedValues = async (value, _id, tokenUser) => {
    const email = getEmail();
    const client = await clientsAPI.getClientByCompanyAndEmail(COMPANY, email);
    const allSharedValues = await getSharedFieldValuesDataByUserId(client._id, tokenUser);
    const foundSharedValue = allSharedValues.find(({ field }) => field._id === _id);
    await updateSharedFieldValuesData(foundSharedValue._id, { ...foundSharedValue, value }, tokenUser);
  };

  const onSubmitPhone = async (event, value, _id) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      startLoading();
      const userObject = {
        company: COMPANY,
        phone: `+521${value}`,
        model: 'client',
        fullName: getFullName(),
      };

      const phoneExist = await checkPhoneAlreadyExists(userObject);

      if (phoneExist) {
        setShowInformationDialog(true);
        endLoading();
        return;
      }

      setSimpleInputDialogData(INITIAL_SIMPLE_INPUT_DIALOG);
      await generateCode(userObject, value);
      endLoading();
    } catch (e) {
      endLoading();
      if (e instanceof AuthError) {
        setShowModal(true);
        return;
      }
      await logError({
        message: 'Error al verificar el numero',
        error: e,
        level: LogLevel.CRITICAL,
        origin: 'CreateClient - verifyCode',
      });
    } finally {
      endLoading();
    }
  };

  const backPhone = () => {
    setShowSendCodeDialog(false);
    const [inputPhone] = findFields([PHONE]);
    setSimpleInputDialogData({ ...INITIAL_SIMPLE_INPUT_DIALOG, show: true, input: inputPhone });
  };

  useEffect(() => {
    fetchData();
    updateCurrentStep(1);
  }, []);

  useEffect(() => {
    if (steps[current].completed) {
      storeStepData({ stepRoute: steps[current + 1].route, currentStep: current + 1, steps });
      navigateWithQueryParam(steps[current + 1].route, 'inmobiliaria');
    }
  }, [steps, current]);

  return (
    <>
      <AlertDialog
        onCancel={() => setShowModal(false)}
        onAccept={() => navigateWithQueryParam(LOGIN_ROUTE, 'inmobiliaria')}
        title="Intentas registrate con un correo existente"
        showModal={showModal}
        onCancelText="Registrarse con otro correo"
        onAcceptText="Ir al login"
      />
      <SendCodeDialog
        onCancel={() => setShowSendCodeDialog(false)}
        backPhone={backPhone}
        onSubmit={verifyCode}
        phone={phoneValue}
        showModal={showSendCodeDialog}
        error={isErrorPhoneValue}
        title="Verifica tu celular"
        useEditPhone
        buttonText="Continuar"
      />
      <InformationDialog
        showModal={showInformationDialog}
        onSubmit={() => navigateWithQueryParam(LOGIN_ROUTE, 'inmobiliaria')}
        buttonTitle="Ingresar a Chiplo"
        descripcion="El celular que estás ingresando con nosotros ya tiene una cuenta. Por favor ingresa a Chiplo con tu cuenta."
      />
      <SimpleInputDialog
        onCancel={() => setSimpleInputDialogData({ ...INITIAL_SIMPLE_INPUT_DIALOG, show: false })}
        onSubmit={onSubmitPhone}
        field={simpleInputDialogData.input}
        showModal={simpleInputDialogData.show}
        error={simpleInputDialogData.error}
        title={simpleInputDialogData.title}
        buttonTitle={simpleInputDialogData.buttonTitle}
      />
      <Loader
        open={isLoading}
      />
      <FormLayout
        submit={onSubmit}
        disabled={isNotCompleted()}
        title={pageConfig.title}
        className={createAccountStrategy.getTitleClassName(classes)}
        subTitle={pageConfig.subtitle}
        titleContainerClass={classes.title}
        buttonTitle={pageConfig.buttonTitle}
        renderHeader={createAccountStrategy.renderHeader({ classes, title: pageConfig?.title, subtitle: pageConfig?.subtitle })}
        renderButton={createAccountStrategy.renderButton({ classes, buttonTitle: pageConfig?.buttonTitle, disabled: isNotCompleted() })}
      >
        <div
          className={classes.innerContainer}
        >
          {
            sections.map(({ fields, title }, index) => (
              <GroupFields
                key={title}
                fields={fields}
                title={title}
                position={index}
                onChange={handleChange}
              />
            ))
          }
          {
            agentSection && (
              <GroupFields
                fields={agentSection.fields}
                title={agentSection.title}
              />
            )
          }
          <TermsAndConditions
            product={{
              productName: 'creditohipotecario',
              page: 'createaccount',
            }}
            handleChange={() => setAcceptTermsAndConditions((value) => !value)}
            checked={acceptTermsAndConditions}
          />
        </div>
      </FormLayout>
    </>

  );
};

export default CreateClient;
