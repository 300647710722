/**
 * Checks if the provided value is a valid object.
 * A valid object is non-null, of type 'object', and has at least one key.
 *
 * @param {any} object - The value to check.
 * @returns {boolean} - Returns true if the value is a valid object, otherwise false.
 */
const isValidObject = (object) => Boolean(object && typeof object === 'object' && Object.keys(object).length > 0);

/**
 * Checks if the provided value is a valid array.
 * A valid array is non-null and is an instance of Array.
 *
 * @param {any} array - The value to check.
 * @returns {boolean} - Returns true if the value is a valid array, otherwise false.
 */
const isValidArray = (array) => Boolean(array && Array.isArray(array));

/**
 * Checks if the provided array is valid and contains items.
 *
 * @param {any} array - The array to check.
 * @returns {boolean} - Returns true if the array is valid and has at least one item
 */
const isArrayWithItems = (array) => Boolean(isValidArray(array) && array.length > 0);

/**
 * Checks if the provided value is an object.
 * An object is of type 'object' and is not null.
 *
 * @param {any} object - The value to check.
 * @returns {boolean} - Returns true if the value is an object, otherwise false.
 */
const isObject = (object) => Boolean(typeof object === 'object' && object !== null);

/**
 * Checks if the provided object is empty.
 * An empty object is a valid object with no keys.
 *
 * @param {any} object - The object to check.
 * @returns {boolean} - Returns true if the object is empty, otherwise false.
 */
const isEmptyObject = (object) => Boolean(isObject(object) && Object.keys(object).length === 0);

/**
 * Checks if the provided object contains all specified keys.
 *
 * @param {{[key: string]: any}} object - The object to check.
 * @param {string[]} keys - The array of keys to check for in the object.
 * @returns {boolean} - Returns true if the object contains all specified keys, otherwise false.
 */
const isObjectWithKeys = (
  object,
  keys,
) => Boolean(isObject(object) && keys.every((key) => key in object));

/**
 * Converts an object to a string if it is an object, otherwise returns the value as is.
 *
 * @param {any} value - The value to convert.
 * @returns {string} - The string representation of the object or the original string.
 */
const convertObjectToString = (value) => {
  if (!isEmptyObject(value)) {
    return JSON.stringify(value);
  }
  return value;
};

export const objectUtils = {
  isValidObject,
  isValidArray,
  isArrayWithItems,
  isObject,
  isEmptyObject,
  isObjectWithKeys,
  convertObjectToString,
};
