import { PRODUCTION_ENV } from '../constants';

const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_SESSION_REWIND_API_KEY,
} = process.env;

export const ENVS = {
  SESSION_REWIND_API_KEY: REACT_APP_SESSION_REWIND_API_KEY,
  ENVIRONMENT: REACT_APP_ENVIRONMENT,
};

export const IS_PRODUCTION = REACT_APP_ENVIRONMENT === PRODUCTION_ENV;
