import { getStepperTags, storeStepData } from '../../../utils';

const getActualStep = (stepperTags) => {
  const actualIndexStep = stepperTags.findIndex(({ completed }) => !completed);
  return { stepRoute: stepperTags[actualIndexStep].route, currentStep: actualIndexStep };
};

export const getStepsTagsByProcessId = async (process, normalizedFlowName) => {
  const stepperInitialization = await getStepperTags(normalizedFlowName, process);
  const { steps } = stepperInitialization;
  const actualStep = getActualStep(steps);
  storeStepData({ ...actualStep, steps });
  return {
    ...stepperInitialization,
    current: actualStep.currentStep,
    actualStep,
  };
};
