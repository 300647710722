import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Button, Typography } from '@material-ui/core';

import { NormalLayout } from '../../../layouts/NormalLayout';
import { SimpleModal } from '../../../../../components/Modal';
import { Simulator } from '../../../../../components/Simulator';
import { BankBestOptionContainer, BanksTableComparative } from '../../../../../components';
import { TabsContext } from '../../../../../context/tabs/tabsContext';
import { PerfiladorHeaderTabs } from '../../../../perfilador/components/PerfiladorHeaderTabs';
import { ModalChangeOptions } from '../../../../../components/ModalChangeOptions';

import useLoading from '../../../../../hooks/useLoading';
import { useMatrizProfilling } from '../../../hooks/useMatrizProfilling';
import { useFieldValues } from '../../../hooks/useFieldValues';

import * as fieldApi from '../../../../../api/fields';
import * as fieldValuesAPI from '../../../../../api/fieldValue';

import { getProcess, getToken } from '../../../../../utils';
import { formatFieldValueToLocal, formatFieldValueToStore } from '../../../../../utils/fieldValues';

import {
  fetchCreditDataFromFields,
  getConditonsAlreadyEditted,
  getCredit,
  getCreditStoredData,
  storeCredit,
  updateCreditDataByMatrizData,
} from '../../../../../utils/credit';

import {
  COMPANY,
  MODAL_PROPIEDAD_EXTRA_OPTIONS,
  PERFILAMIENTO_FIELD_NAME,
  FIELDS_MODAL_EXTRA_OPTIONS,
  PROFILING_EMPTY_MATRIZ,
  BEST_FINANTIAL_OPTION,
  BEST_INITIAL_OUTLAY,
  DATA_TABS_BESTS_MATRIZ_OPTIONS,
  PERFILADOR_VARIANTE_COTIZATION,
  OPTION_SELECTED_FIELD_ID,
  OPTION_SELECTED_FIELD_NAME,
} from '../../../../../constants';

import useStyles from './useStyles';
import { CheckBoxItem } from '../../../../../components/CheckBoxItem';
import { useLogger } from '../../../../../hooks/useLogger';
import { LogLevel } from '../../../../../api/logs';

const BaseCreditProfilingCotization = ({
  createUpdateTag,
  disabled,
  handleFinishPage,
  pageData,
  goTo,
  profilingStrategy,
  children,
  setPageData,
  showComponent,
  autoNodes,
  isLoading,
  checkSendEmail,
  onChangeCheckSendEmail,
}) => {
  const { initTabs } = useContext(TabsContext);
  const classes = useStyles();
  const { isLoading: isMainLoading, startLoading, endLoading } = useLoading();
  const processId = getProcess();
  const {
    logError,
  } = useLogger();
  const {
    getMatrizProductStructuredNewFormat,
    isPerfilamientoEmptyOrApplied,
    updateClientConditionsValues,
    handleMatrizSubmission,
    sortByCat,
  } = useMatrizProfilling({ company: COMPANY });
  const {
    formatFromFieldValueModelToUpload,
    getFieldValueByName,
    getFieldValueById,
    storeLocalFieldValues,
  } = useFieldValues({ company: COMPANY, process: processId });

  const [showModalCredit, setShowModalCredit] = useState(false);
  const [bestOption, setBestOption] = useState({});
  const [listComparative, setListComparative] = useState([]);
  const [openChangeOptions, setOpenChangeOptions] = useState(false);
  const [bestOptionDefault, setBestOptionDefault] = useState({});
  const [bestInitialOutlay, setBestInitialOutlay] = useState({});
  const [fieldsExtraOptions, setFieldsExtraOptions] = useState([]);
  const [actualOption, setActualOption] = useState(MODAL_PROPIEDAD_EXTRA_OPTIONS);
  const [bestFinantialOption, setBestFinantialOption] = useState({});
  const [extraOptionsFormated, setExtraOptionsFormated] = useState([]);
  const [listComparativeDefault, setListComparativeDefault] = useState([]);
  const [conditionsAlreadyEddited, setConditionsAlreadyEddited] = useState(false);

  const getOptionSelected = (value) => ({
    field: OPTION_SELECTED_FIELD_ID,
    name: OPTION_SELECTED_FIELD_NAME,
    value,
  });

  const storeFieldsOnChangeProduct = async (fieldValues, type) => {
    if (processId) return;
    const fieldsFormated = formatFromFieldValueModelToUpload(fieldValues, true);
    const optionSelected = getOptionSelected(type);
    const newFieldValues = [...fieldsFormated, optionSelected];
    storeLocalFieldValues(newFieldValues);
  };

  const saveOptionSelected = async (value) => {
    if (!processId) return;
    await fieldValuesAPI.saveFieldValue({
      field: OPTION_SELECTED_FIELD_ID,
      process: processId,
      value,
    });
  };

  const changeBestProduct = async (type) => {
    startLoading();
    await saveOptionSelected(type);
    switch (type) {
      case BEST_FINANTIAL_OPTION:
        setBestOption(
          bestFinantialOption?.productsCompared?.bestProduct
            || bestOptionDefault
            || {},
        );
        setListComparative(sortByCat(bestFinantialOption?.productsCompared?.comparassion || []));
        storeFieldsOnChangeProduct(bestFinantialOption?.data?.fieldValues || [], type);
        if (Object.keys(bestFinantialOption?.newConditions).length) {
          updateClientConditionsValues(
            bestFinantialOption?.newConditions,
            processId,
          );
        }
        break;
      case BEST_INITIAL_OUTLAY:
        setBestOption(bestInitialOutlay?.productsCompared?.bestProduct || bestOptionDefault || {});
        setListComparative(sortByCat(bestInitialOutlay?.productsCompared?.comparassion || []));
        storeFieldsOnChangeProduct(bestInitialOutlay?.data?.fieldValues || [], type);
        if (Object.keys(bestInitialOutlay?.newConditions).length) {
          updateClientConditionsValues(
            bestInitialOutlay?.newConditions,
            processId,
          );
        }
        break;
      default:
        setBestOption(bestOptionDefault);
        setListComparative(sortByCat(listComparativeDefault));
        break;
    }
    endLoading();
  };

  const formatExtraOptions = async () => {
    const newExtraOptions = await Promise.all(
      FIELDS_MODAL_EXTRA_OPTIONS.map(async (extraOption) => {
        const newFields = await Promise.all(
          extraOption.fields.map(async (fieldName) => {
            const fiedlData = await fieldApi.getFieldByCompanyAndName(
              COMPANY,
              fieldName,
            );
            const value = await getFieldValueByName(fieldName);
            return { ...fiedlData, value: value || '' };
          }),
        );
        return { ...extraOption, fields: newFields };
      }),
    );
    setExtraOptionsFormated(newExtraOptions);
  };

  const closeModalOptions = () => {
    setOpenChangeOptions(false);
    setActualOption(MODAL_PROPIEDAD_EXTRA_OPTIONS);
    formatExtraOptions();
  };

  const setBanks = async () => {
    const perfilamientoCrediticioValues = await getFieldValueByName(PERFILAMIENTO_FIELD_NAME);
    if (isPerfilamientoEmptyOrApplied(perfilamientoCrediticioValues)) {
      await createUpdateTag({ currentPage: PROFILING_EMPTY_MATRIZ })();
      goTo();
      return;
    }
    const {
      customTitle,
      bestProduct = {},
      comparassion = [],
      bestFinantialOption: bestFinantial = {},
      bestInitialOutlay: bestInitial = {},
      message,
      data = null,
    } = getMatrizProductStructuredNewFormat(perfilamientoCrediticioValues);

    const creditData = await fetchCreditDataFromFields({ getFieldValueById });

    if (data) {
      updateCreditDataByMatrizData(data);
      const updatedCreditData = getCredit();

      storeCredit({
        ...creditData,
        ...updatedCreditData,
      });
    } else {
      const oldCreditData = getCredit();
      storeCredit({
        ...oldCreditData,
        ...creditData,
      });
    }
    setPageData((prevPageData) => ({
      ...prevPageData, title: customTitle, componentTitle: '', subtitle: message,
    }));
    setBestOptionDefault(bestProduct);
    setBestInitialOutlay(bestInitial);
    setBestFinantialOption(bestFinantial);
    setListComparativeDefault(sortByCat(comparassion));
  };

  const getFieldsForExtraOptions = async () => {
    const fieldNames = FIELDS_MODAL_EXTRA_OPTIONS.reduce(
      (acc, curr) => [...acc, ...curr.fields],
      [],
    );

    const fieldsData = await Promise.all(
      fieldNames.map(
        (fieldName) => fieldApi.getFieldByCompanyAndName(COMPANY, fieldName),
      ),
    );
    setFieldsExtraOptions(fieldsData);
  };

  const fetchData = async () => {
    try {
      startLoading();
      await setBanks();
      await getFieldsForExtraOptions();
      endLoading();
    } catch (e) {
      await logError({
        message: 'Error al cargar los datos de perfilamiento',
        error: e,
        level: LogLevel.CRITICAL,
        origin: 'BaseCreditProfilingCotization - fetchData',
      });
      endLoading();
    }
  };

  const onSubmit = async () => {
    try {
      startLoading();
      await handleFinishPage({ bestOption });
      endLoading();
    } catch (error) {
      await logError({
        message: 'Error al finalizar el perfilamiento',
        error,
        level: LogLevel.CRITICAL,
        origin: 'BaseCreditProfilingCotization - onSubmit',
      });
      endLoading();
    }
  };

  const handleUpdateProcessFieldValues = async (fieldValuesFormatted, fieldsDefault, token) => {
    if (!token) return;
    await Promise.all(
      fieldValuesFormatted.map(
        (fieldValue) => fieldValuesAPI.saveFieldValue(fieldValue, token),
      ),
    );
    await Promise.all(
      fieldsDefault.map(({ value, field }) => fieldValuesAPI.saveFieldValue(
        { value, field, process: processId },
        token,
      )),
    );
  };

  const updateMatrizOnExtraOptions = async (fieldValues, updateConditons, tokenUser = '') => {
    startLoading();
    if (updateConditons) {
      const { enganche, monthlyPaid, year } = getCreditStoredData();
      const newConditions = {
        propiedadvalor: fieldValues.find(({ name }) => name === 'propiedadvalor')?.value,
        enganche: enganche.toString(),
        pagomensual: monthlyPaid.toString(),
        plazo: (year * 12).toString(),
      };
      updateClientConditionsValues(newConditions, processId);
    }
    await handleMatrizSubmission(tokenUser);
    setOpenChangeOptions(false);
  };

  const onSubmitOptions = async (fieldValues, radioValue, fieldsDefault) => {
    const isFieldsIncomplete = fieldValues.every(({ value }) => !value);
    const token = getToken();

    if (!isFieldsIncomplete && radioValue === 'si') {
      const fieldValuesFormattedLocal = fieldValues.map((field) => formatFieldValueToLocal(field));
      storeLocalFieldValues(([...fieldValuesFormattedLocal, ...fieldsDefault]));
      const fieldValuesFormatted = fieldValues.map(
        (field) => formatFieldValueToStore(field, field.value, processId),
      );
      await handleUpdateProcessFieldValues(fieldValuesFormatted, fieldsDefault, token);
      if (actualOption === 'modalPropiedad') updateMatrizOnExtraOptions(fieldValues, true, token);
      else updateMatrizOnExtraOptions(fieldValues, false, token);
    } else if (actualOption === 'modalCoacreditado') {
      setActualOption('modalPropiedad');
      setOpenChangeOptions(false);
    } else if (actualOption === 'modalPropiedad') {
      setActualOption('modalCoacreditado');
    }
  };

  useEffect(() => {
    initTabs({
      tabs: DATA_TABS_BESTS_MATRIZ_OPTIONS,
      default: DATA_TABS_BESTS_MATRIZ_OPTIONS[0].value,
      variant: PERFILADOR_VARIANTE_COTIZATION,
    });
    fetchData();
  }, []);

  useEffect(() => {
    if (fieldsExtraOptions.length && !extraOptionsFormated.length) {
      formatExtraOptions();
    }
  }, [fieldsExtraOptions]);

  useEffect(() => {
    const conditionsArdyEddited = getConditonsAlreadyEditted();
    setConditionsAlreadyEddited(conditionsArdyEddited);
    const {
      productsCompared: {
        bestProduct: bestFinantial = {},
        comparassion: listComparativeFin = [],
      } = {},
      productsApplied: productsAppliedFin = [],
      newConditions: newConditionsFin = {},
    } = bestFinantialOption || {};
    const {
      productsCompared: {
        bestProduct: bestInitial = {},
        comparassion: listComparativeOut = [],
      } = {},
      productsApplied: productsAppliedOut = [],
      newConditions: newConditionsOut = {},
    } = bestInitialOutlay || {};
    if (Object.keys(bestInitial).length && productsAppliedOut.length && !conditionsArdyEddited) {
      setBestOption(bestInitial);
      setListComparative(sortByCat(listComparativeOut));
      updateClientConditionsValues(newConditionsOut);
      storeFieldsOnChangeProduct(bestInitialOutlay?.data?.fieldValues || [], 'bestInitialOutlay');
      saveOptionSelected('bestInitialOutlay');
    } else if (
      Object.keys(bestFinantial).length && productsAppliedFin.length && !conditionsArdyEddited
    ) {
      setBestOption(bestFinantial);
      setListComparative(sortByCat(listComparativeFin));
      updateClientConditionsValues(newConditionsFin);
      storeFieldsOnChangeProduct(bestInitialOutlay?.data?.fieldValues || [], 'bestFinantialOption');
      saveOptionSelected('bestFinantialOption');
    } else {
      setBestOption(bestOptionDefault);
      setListComparative(sortByCat(listComparativeDefault));
    }
  }, [bestFinantialOption, bestInitialOutlay, bestOptionDefault]);

  return (
    <NormalLayout
      submit={onSubmit}
      isLoading={isLoading || isMainLoading}
      disabled={disabled}
      show={showComponent}
      classesNames={profilingStrategy.getTitleClassName(classes)}
      renderButton={profilingStrategy.renderButton({
        classes,
        buttonTitle: pageData.buttonTitle,
        buttonFooterTitle: pageData.buttonFooterTitle || '',
        disabled,
      })}
      data={{
        buttonTitle: pageData.buttonTitle || 'Continuar',
      }}
    >
      <div className={classes.container}>
        <SimpleModal
          onCancel={() => setShowModalCredit(false)}
          showModal={showModalCredit}
          title="Edita las condiciones de tu crédito"
        >
          <Simulator
            startLoading={startLoading}
            endLoading={endLoading}
            onSubmitClick={createUpdateTag}
            autoNodes={autoNodes}
            shouldExecMatriz
          />
        </SimpleModal>
        <Container
          className={classes.FactorajeDataContainer}
          disableGutters
          maxWidth={false}
        >
          {pageData.title ? (
            <Typography
              component="h2"
              className={classes.title}
            >
              {pageData.title}
            </Typography>
          ) : ''}
          {pageData.infoTitle ? (
            <Typography
              component="p"
              className={classes.infoTitle}
            >
              {pageData.infoTitle}
            </Typography>
          ) : ''}
          {pageData.componentTitle && (
            <Typography
              component="h2"
              className={classes.messageWhats}
            >
              {pageData.componentTitle}
            </Typography>
          )}
          {profilingStrategy.renderSubtitle({
            classes,
            ...pageData,
            children: (
              <Button
                onClick={() => setShowModalCredit(true)}
                onKeyDown={() => setShowModalCredit(true)}
                component="h2"
                className={classes.buttonSimulator}
              >
                Editar condiciones
              </Button>
            ),
          })}
          <BankBestOptionContainer
            title={pageData?.titleBestOption || ''}
            imageUrl={bestOption?.imageUrl || ''}
            cat={bestOption?.cat || ''}
            mensualidad={bestOption?.mensualidad || 0}
            tasa={bestOption?.tasa || 0}
            producto={bestOption?.producto || ''}
          />
          {!conditionsAlreadyEddited && (
            <>
              <PerfiladorHeaderTabs
                handleChangeActiveTab={(value) => changeBestProduct(value)}
              />
              <div className={classes.containerExtraOptions}>
                <Button
                  onClick={() => setOpenChangeOptions(true)}
                  component="h3"
                  className={classes.buttonExtraOptions}
                  disabled={!bestFinantialOption?.productsApplied?.length}
                >
                  ¿Qué otras opciones tengo?
                </Button>
              </div>
            </>
          )}
          <div className={classes.container}>
            <BanksTableComparative
              banksList={listComparative}
              title={pageData?.banksComparativeTitle || ''}
            />
            {children}
            {pageData?.checkSendEmailActive && (
            <CheckBoxItem
              checked={checkSendEmail}
              handleChange={onChangeCheckSendEmail}
              text={pageData?.checkSendEmailText}
              classNames={{
                label: classes.labelCheckSendEmail,
                containerCheck: classes.containerCheckSendEmail,
              }}
            />
            )}
          </div>
        </Container>
      </div>
      <ModalChangeOptions
        type={actualOption}
        onCancel={closeModalOptions}
        showModal={openChangeOptions}
        options={extraOptionsFormated}
        onSubmit={onSubmitOptions}
      />
      {profilingStrategy.renderFooterWhatsapp({ classes })}
    </NormalLayout>
  );
};

BaseCreditProfilingCotization.propTypes = {
  disabled: PropTypes.bool,
  createUpdateTag: PropTypes.func.isRequired,
  handleFinishPage: PropTypes.func.isRequired,
  profilingStrategy: PropTypes.shape({
    renderSubtitle: PropTypes.func,
    renderFooterWhatsapp: PropTypes.func,
    getTitleClassName: PropTypes.func,
    renderButton: PropTypes.func,
  }),
  pageData: PropTypes.shape({
    title: PropTypes.string,
    infoTitle: PropTypes.string,
    banksComparativeTitle: PropTypes.string,
    titleBestOption: PropTypes.string,
    componentTitle: PropTypes.string,
    checkSendEmailActive: PropTypes.bool,
    buttonTitle: PropTypes.string,
    checkSendEmailText: PropTypes.string,
    buttonFooterTitle: PropTypes.string,
  }),
  children: PropTypes.node,
  setPageData: PropTypes.func,
  showComponent: PropTypes.bool,
  goTo: PropTypes.func,
  autoNodes: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  checkSendEmail: PropTypes.bool,
  onChangeCheckSendEmail: PropTypes.func,
};

BaseCreditProfilingCotization.defaultProps = {
  disabled: false,
  profilingStrategy: {},
  pageData: {},
  children: null,
  setPageData: () => {},
  showComponent: false,
  goTo: () => {},
  autoNodes: [],
  isLoading: false,
  checkSendEmail: false,
  onChangeCheckSendEmail: () => {},
};

export default BaseCreditProfilingCotization;
