/* eslint-disable import/no-extraneous-dependencies */
import { BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';

import { AppRouter } from './router/AppRouter';

import { StepperProvider } from './context/stepper/stepperProvider';
import { UIProvider } from './context/ui/uiProvider';
import { AuthProvider } from './context/auth/authProvider';

import AppTheme from './themes';
import { ThemeProvider } from './context/Theme';
import { ClientProvider } from './context/client/clientProvider';

import { store } from './store/store';
import { FlowStrategyProvider } from './context/flowStrategy';
import { useSessionRewind } from './hooks/useSessionRewind';
import { ErrorProvider } from './context/error/errorProvider';

const {
  REACT_APP_GOOGLE_ANALYTICS,
} = process.env;

ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS);

function App() {
  const { start } = useSessionRewind();

  useEffect(() => {
    start();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider>
        <ErrorProvider>
          <AppTheme>
            <FlowStrategyProvider>
              <AuthProvider>
                <UIProvider>
                  <StepperProvider>
                    <ClientProvider>
                      <BrowserRouter>
                        <AppRouter />
                      </BrowserRouter>
                    </ClientProvider>
                  </StepperProvider>
                </UIProvider>
              </AuthProvider>
            </FlowStrategyProvider>
          </AppTheme>
        </ErrorProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
