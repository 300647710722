import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '../../../../components';

import useLoading from '../../../../hooks/useLoading';

import { StepperContext } from '../../../../context/stepper';
import { ThemeContext } from '../../../../context/Theme/themeContext';
import { AuthContext } from '../../../../context/auth';

import {
  PERFILAMIENTO_REDIRECTION,
  getActualStep,
  getFlowTypeHandler,
  getFullName,
  getStepperTags,
  getUserId,
  handleLogout,
  saveExtraDataAuth,
  storeStepData,
} from '../../../../utils';
import { storeClientExtraData } from '../../../../utils/clientData';
import { loginToken } from '../../../../utils/api/auth.utils';
import { useSessionRewind } from '../../../../hooks/useSessionRewind';
import { useLogger } from '../../../../hooks/useLogger';
import { LogLevel } from '../../../../api/logs';

export const RecoverClientProcess = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const processId = queryParams.get('process');

  const navigate = useNavigate();

  const { initTimeoutToken } = useContext(AuthContext);
  const { initStepper } = useContext(StepperContext);
  const { loadTheme } = useContext(ThemeContext);

  const { isLoading, startLoading, endLoading } = useLoading();
  const { setUserInfo, setSessionInfo } = useSessionRewind();
  const {
    logError,
  } = useLogger();

  const setTags = async (processID, normalizedFlowName) => {
    const stepperInitialization = await getStepperTags(normalizedFlowName, processID);
    initStepper(stepperInitialization);
    const { steps } = stepperInitialization;
    const actualStep = getActualStep(steps);
    initStepper({ ...stepperInitialization, current: actualStep.currentStep });
    storeStepData({ ...actualStep, steps });
    return actualStep;
  };

  const loginByTokenFlow = async () => {
    try {
      if (!queryParams) return false;
      startLoading();
      await handleLogout();
      const userData = await loginToken(token);
      setUserInfo({
        userId: getUserId(),
        userName: getFullName(),
        userEmail: userData.email,
        userPhone: userData.phone,
        userType: 'Cliente',
      });
      setSessionInfo({
        processId,
      });
      const { normalizedFlowName } = await getFlowTypeHandler(processId);
      await setTags(processId, normalizedFlowName);
      saveExtraDataAuth({ process: processId });
      await loadTheme();
      initTimeoutToken();
      storeClientExtraData({ isRecoverClient: true });
      return navigate(PERFILAMIENTO_REDIRECTION);
    } catch (e) {
      await logError({
        error: e,
        level: LogLevel.CRITICAL,
        message: e.message,
        origin: 'loginByTokenFlow',
        data: e,
      });
      return false;
    }
  };

  const handleLoginByToken = async () => {
    startLoading();
    await loginByTokenFlow();
    endLoading();
  };

  useEffect(() => {
    handleLoginByToken();
  }, [token]);

  return (
    <Loader open={isLoading} />
  );
};
