export const FORMALIZATION_REDIRECTION = '/main/formalization';
export const CONSULT_REDIRECTION = '/main/consult';
export const PERFILAMIENTO_REDIRECTION = '/main/perfilamiento';
export const VALID_PATHNAMES_REDIRECTION = [
  CONSULT_REDIRECTION,
  FORMALIZATION_REDIRECTION,
  PERFILAMIENTO_REDIRECTION,
];

export const VALIDATION_PARAMS = {
  [FORMALIZATION_REDIRECTION]: ['process', 'token'],
  [CONSULT_REDIRECTION]: ['process', 'token'],
  [PERFILAMIENTO_REDIRECTION]: ['process', 'token'],
};

export const REDIRECTIONS = {
  [FORMALIZATION_REDIRECTION]: ({
    pathname, process,
  }) => `${pathname}?process=${process}`,
  [CONSULT_REDIRECTION]: ({ pathname, process }) => `${pathname}?process=${process}`,
  [PERFILAMIENTO_REDIRECTION]: ({ pathname, process }) => `${pathname}?process=${process}`,
};

const getValidatedParams = (pathname, searchParams) => {
  const params = VALIDATION_PARAMS[pathname].map((paramKey) => {
    const param = searchParams.get(paramKey);
    return param ? { [paramKey]: param } : null;
  });
  const hasNullValues = params.filter((paramValue) => !paramValue).length > 0;
  if (hasNullValues) return null;
  const paramsObject = params.reduce((acc, curr) => ({
    ...acc,
    ...curr,
  }), {});
  const redirection = REDIRECTIONS[pathname]({ ...paramsObject, pathname });
  return { ...paramsObject, redirection };
};

export const getTokenAndRedirection = (searchParams) => {
  const pathName = searchParams.get('pathname');
  if (!pathName || !VALID_PATHNAMES_REDIRECTION.includes(pathName)) return '';
  const params = getValidatedParams(pathName, searchParams);
  return params;
};

export const getTokenAndRedirectionFromUrl = (searchParams) => {
  const pathName = window.location.pathname;

  const updatedSearchParams = new URLSearchParams(searchParams);

  updatedSearchParams.set('pathname', pathName);

  if (!pathName || !VALID_PATHNAMES_REDIRECTION.includes(pathName)) return '';
  const params = getValidatedParams(pathName, updatedSearchParams);
  return params;
};
