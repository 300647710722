import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LandingContext } from './landingContext';
import {
  CHIPLO_INMOBILIARIA_ID,
  CIFRAS_INMOBILIARIA_ID,
  COMPANY,
  LANDING_PAGE_CHIPLO_CONFIG_NAME,
} from '../../constants';
import { DataLandingChiplo, DataLandingCifras } from '../../data';
import { Loader } from '../../components';
import { getLastPageConfigByEntityAndName } from '../../api/pageConfigs';
import { objectUtils } from '../../utils';
import { decryptData } from '../../utils/encryption';
import { IS_PRODUCTION } from '../../config/envs';

export const LandingProvider = ({ children, inmobiliariaId }) => {
  const [landingData, setLandingData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const currentInmobiliariaId = inmobiliariaId || CHIPLO_INMOBILIARIA_ID;
  const value = useMemo(
    () => ({
      landingData,
      inmobiliariaId: currentInmobiliariaId,
    }),
    [currentInmobiliariaId, landingData],
  );

  const getStaticDataByInmobiliariaId = () => {
    switch (currentInmobiliariaId) {
      case CIFRAS_INMOBILIARIA_ID:
        return DataLandingCifras;
      case CHIPLO_INMOBILIARIA_ID:
      default:
        return DataLandingChiplo;
    }
  };

  const handleLoadFetchPageConfig = async () => {
    setIsLoading(true);
    const pageConfig = await getLastPageConfigByEntityAndName(
      currentInmobiliariaId,
      COMPANY,
      LANDING_PAGE_CHIPLO_CONFIG_NAME,
    );
    if (objectUtils.isValidObject(pageConfig)) {
      const { payload } = pageConfig;
      if (!IS_PRODUCTION) {
        setLandingData(payload);
        return;
      }
      const uncrpytedPayload = decryptData(payload);
      setLandingData(JSON.parse(uncrpytedPayload));
    } else {
      throw new Error('No page config found');
    }
  };
  const fetchPageConfig = async () => {
    try {
      await handleLoadFetchPageConfig();
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching page config', error);
      const data = getStaticDataByInmobiliariaId();
      setLandingData(data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPageConfig();
  }, [inmobiliariaId]);

  if (isLoading) {
    return <Loader open={isLoading} />;
  }

  return (
    <LandingContext.Provider value={value}>
      {children}
    </LandingContext.Provider>
  );
};

LandingProvider.propTypes = {
  children: PropTypes.node.isRequired,
  inmobiliariaId: PropTypes.string,
};

LandingProvider.defaultProps = {
  inmobiliariaId: '',
};
