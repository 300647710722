import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import AlertSnack from '../../components/AlertSnack';
import ErrorMessage from '../../components/ErrorMessage';
import { ErrorContext } from './errorContext';

export const ErrorProvider = ({ children }) => {
  const [isError, setIsError] = useState(false);
  const [errorType, setErrorType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const enableError = useCallback(() => {
    setIsError(true);
  }, []);

  const disableError = useCallback(() => {
    setIsError(false);
  }, []);

  const setError = ({
    message,
  }) => {
    enableError();
    setErrorType('error');
    setErrorMessage(message);
  };

  const hideError = () => {
    disableError();
    setErrorType('');
    setErrorMessage('');
  };

  const value = useMemo(() => ({
    setError,
    hideError,
  }), [
    setError,
    hideError,
  ]);

  return (
    <ErrorContext.Provider value={value}>
      <AlertSnack
        isOpen={isError}
        type="error"
        onClose={hideError}
      >
        <ErrorMessage
          errorType={errorType}
          errorMessage={errorMessage}
        />
      </AlertSnack>
      {
        children
      }
    </ErrorContext.Provider>
  );
};

ErrorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
