import { useState } from 'react';
import { ENVS, IS_PRODUCTION } from '../config/envs';
import { AGENT_VIEWER_ROLE_NAME, CLIENT_ROLE_NAME } from '../constants/roles';
import {
  getEmail,
  getFullName,
  getProcess,
  getUserId,
  isAuth,
  isUserAgent,
  objectUtils,
} from '../utils';

const BASE_REWIND_CONFIG = {
  apiKey: ENVS.SESSION_REWIND_API_KEY,
  startRecording: true,
  environment: ENVS.ENVIRONMENT,
};

export const useSessionRewind = () => {
  const [isSessionRewindLoaded, setIsSessionRewindLoaded] = useState(() => {
    const w = window;
    return Boolean(w?.sessionRewind);
  });

  const setAutoUserInfo = () => {
    const w = window;
    if (!isAuth()) return;
    const userData = {
      userId: getUserId(),
      userName: getFullName(),
      userEmail: getEmail(),
      userType: isUserAgent() ? CLIENT_ROLE_NAME : AGENT_VIEWER_ROLE_NAME,
    };
    w?.sessionRewind?.identifyUser(userData);
  };
  const setAutoSessionInfo = () => {
    const w = window;
    if (!isAuth()) return;
    const sessionInfo = {
      processId: getProcess(),
    };
    w?.sessionRewind?.setSessionInfo(sessionInfo);
  };

  const onLoad = () => {
    if (!IS_PRODUCTION) return setIsSessionRewindLoaded(true);
    window.sessionRewind.getSessionUrl(() => {});
    setAutoUserInfo();
    setAutoSessionInfo();
    return setIsSessionRewindLoaded(true);
  };

  const rewindConfig = {
    ...BASE_REWIND_CONFIG,
    onLoad,
  };

  const getRewindConfig = () => {
    const isAuthenticated = isAuth();
    if (!isAuthenticated) return rewindConfig;
    const userId = getUserId();
    const userName = getFullName();
    const userEmail = getEmail();
    const isClient = isUserAgent();
    const userType = isClient ? CLIENT_ROLE_NAME : AGENT_VIEWER_ROLE_NAME;

    const newRewindConfig = {
      ...rewindConfig,
      ...(isAuthenticated && {
        userId,
        userName,
        userEmail,
        userType,
      }),
    };
    return newRewindConfig;
  };

  const start = () => {
    if (!IS_PRODUCTION) return;
    const w = window;
    w.SessionRewindConfig = getRewindConfig();
    const script = document.createElement('script');
    script.async = true;
    script.crossOrigin = 'anonymous';
    script.src = 'https://rec.sessionrewind.com/srloader.js';

    const head = document.getElementsByTagName('head')[0];
    head.insertBefore(script, head.firstChild);
  };

  const setUserInfo = ({
    userId,
    userName = '',
    userEmail = '',
    userPhone = '',
    userType = '',
  }) => {
    if (!IS_PRODUCTION) return;
    const w = window;
    const userData = {
      userId,
      ...(userName && { userName }),
      ...(userEmail && { userEmail }),
      ...(userPhone && { userPhone }),
      ...(userType && { userType }),
    };
    const oldUserInfo = w?.sessionRewind?.getUserInfo((userInf) => {
      if (!objectUtils.isValidObject(userInf)) return null;
      return userInf;
    }) || {};
    const newUserInfo = {
      ...(oldUserInfo && { ...oldUserInfo }),
      ...userData,
    };
    w?.sessionRewind?.identifyUser(newUserInfo);
  };

  const setSessionInfo = (sessionInfo) => {
    if (!IS_PRODUCTION) return;
    const w = window;
    w?.sessionRewind?.setSessionInfo(sessionInfo);
  };

  const getSessionUrlCurrentTime = () => {
    if (!IS_PRODUCTION) return '';
    const w = window;
    const url = w?.sessionRewind?.getSessionUrl({
      withTimestamp: true,
      offset: 5000,
    });
    return url;
  };

  const logSessionError = (error, extraData = {}) => {
    if (!IS_PRODUCTION) return null;
    const w = window;
    if (!w.sessionRewind) return null;
    if (objectUtils.isEmptyObject(extraData)) return w.sessionRewind.logError(error);
    return w.sessionRewind.logError(error, extraData);
  };

  return {
    isSessionRewindLoaded,
    start,
    setUserInfo,
    setSessionInfo,
    getSessionUrlCurrentTime,
    logSessionError,
  };
};
