import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet, Navigate } from 'react-router-dom';
import { isAuth, isUserAgent } from '../../utils';
import { isAllowCurrentStep } from '../../utils/stepper';
import {
  AUTH_ROUTE_TYPE,
  MAIN_ROUTE_TYPE,
  NOTAUTH_ROUTE_TYPE,
  ONLY_AGENTS_AND_NOT_AUTH_CLIENTS,
  ONLY_AGENTS_ROUTE_TYPE,
  ONLY_CLIENTS_ROUTE_TYPE,
} from '../../constants/routeTypes';
import { Loader } from '../Loader';

const isAllowed = (type, additionalData) => {
  const isAgentLogged = isUserAgent();
  const { authProtected } = additionalData;
  const isAuthProtected = authProtected ? isAuth() : !isAuth();

  switch (type) {
    case AUTH_ROUTE_TYPE:
      return isAuthProtected;
    case NOTAUTH_ROUTE_TYPE:
      return true;
    case ONLY_AGENTS_ROUTE_TYPE:
      return isAgentLogged;
    case ONLY_CLIENTS_ROUTE_TYPE:
      return !isAgentLogged;
    case ONLY_AGENTS_AND_NOT_AUTH_CLIENTS:
      return isAgentLogged || !isAuth();
    case MAIN_ROUTE_TYPE:
    default:
      const { step } = additionalData;
      return isAllowCurrentStep(step);
  }
};

const getqueryParams = () => {
  const { search = '', pathname } = window.location;
  const queryParams = search ? `${search}${pathname ? `&pathname=${pathname}` : ''}` : '';
  return queryParams;
};

export const ProtectedRoute = ({
  children, redirectPath, type, additionalData,
}) => {
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    const checkAuth = () => {
      // eslint-disable-next-line no-promise-executor-return
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      setIsAuthChecked(true);
    };

    checkAuth();
  }, []);

  if (!isAuthChecked) {
    return <Loader open />;
  }

  if (!isAllowed(type, additionalData)) {
    const queryParams = getqueryParams();
    return <Navigate to={`${redirectPath}${queryParams}`} replace />;
  }

  return children || <Outlet />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  redirectPath: PropTypes.string,
  type: PropTypes.string.isRequired,
  additionalData: PropTypes.shape({
    step: PropTypes.number,
    authProtected: PropTypes.bool,
  }),
};

ProtectedRoute.defaultProps = {
  additionalData: {},
  redirectPath: '',
};
