import { useContext } from 'react';
import { ErrorContext } from '../context/error/errorContext';
import { createErrorLog } from '../api/logs';
import { useSessionRewind } from './useSessionRewind';
import { objectUtils } from '../utils';

export const useLogger = () => {
  const { setError } = useContext(ErrorContext);
  const { getSessionUrlCurrentTime, logSessionError } = useSessionRewind();

  const logError = async ({
    error,
    level,
    message,
    origin,
    data = '',
    extraData = '',
    errorUrl = '',
  }) => {
    const url = errorUrl || getSessionUrlCurrentTime() || '';
    await createErrorLog({
      level,
      message,
      origin,
      data: data || error || '',
      extraData,
      errorUrl: url,
    });
    setError({ message });
    const extraDataToLog = objectUtils.convertObjectToString(data) || message;
    logSessionError(error, extraDataToLog);
  };

  return {
    logError,
  };
};
