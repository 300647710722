import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loader } from '../../components';
import {
  getFlowTypeHandler,
  getTokenAndRedirectionFromUrl,
  handleLogout,
  saveExtraDataAuth,
} from '../../utils';
import { loginToken } from '../../utils/api/auth.utils';
import { getStepsTagsByProcessId } from '../../containers/main/shared/Login';
import { StepperContext } from '../../context/stepper';
import { ThemeContext } from '../../context/Theme/themeContext';
import { AuthContext } from '../../context/auth';
import { useProduct } from '../../hooks/useProduct';
import { useLogger } from '../../hooks/useLogger';
import { LogLevel } from '../../api/logs';

export const CheckTokenUrl = ({
  children,
}) => {
  const [useLoginByToken, setUseLoginByToken] = useState(true);
  const { initStepper } = useContext(StepperContext);
  const { loadTheme } = useContext(ThemeContext);
  const { products } = useProduct();
  const { initTimeoutToken } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { logError } = useLogger();

  // const token = searchParams.get('token');

  // const logout = async (redirection) => {
  //   try {
  //     console.log('🚀 ~ logout ~ before redirection:');
  //     await handleRedirectLogout(redirection, true);
  //   } catch (e) {
  //     setUseLoginByToken(false);
  //   }
  // };

  // const getRedirection = () => {
  //   const process = searchParams.get('process');
  //   console.log('🚀 ~ getRedirection ~ token:', token);
  //   if (!token || !process) return null;
  //   const { pathname } = window.location;
  //   const redirection = `${LOGIN_ROUTE}?process=${process}&token=${token}&pathname=${pathname}`;
  //   console.log('🚀 ~ getRedirection ~ redirection:', redirection);
  //   return redirection;
  // };

  const loginByTokenFlow = async () => {
    try {
      const queryParams = getTokenAndRedirectionFromUrl(searchParams);
      if (!queryParams) {
        setUseLoginByToken(false);
        return;
      }
      const { token, redirection, process: processId } = queryParams;
      if (!token || !redirection || !processId) {
        setUseLoginByToken(false);
        return;
      }
      await handleLogout(token);
      await loginToken(token);
      const { normalizedFlowName } = await getFlowTypeHandler(processId);
      const stepsTags = await getStepsTagsByProcessId(processId, normalizedFlowName);
      initStepper(stepsTags);
      saveExtraDataAuth({ process: processId });
      await loadTheme(products[0]);
      initTimeoutToken();
      navigate(redirection);
    } catch (e) {
      await logError({
        message: e.message,
        error: e,
        level: LogLevel.CRITICAL,
        origin: 'CheckTokenUrl - loginByTokenFlow',
        extraData: {
          searchParams,
        },
      });
    }
  };

  useEffect(() => {
    // const redirection = getRedirection();
    // if (!redirection) return;
    // const expTime = getExpirationTime(token);
    // if (!expTime) {
    //   setUseLoginByToken(false);
    //   return;
    // }
    // logout(redirection);
    loginByTokenFlow();
  }, [searchParams]);

  return (useLoginByToken && <Loader />) || children;
};

CheckTokenUrl.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
