import { objectUtils } from '../utils/object.utils';
import { createInstanceNoToken, createInstance } from './config';

const BASE_URL = '/login';

export const loginByCredentials = async (email, password, roles = []) => {
  const request = {
    user: {
      email,
      password,
      ...(objectUtils.isArrayWithItems(roles) && { roles }),
    },
  };
  const axios = createInstanceNoToken();
  const { data } = await axios.post(`${BASE_URL}`, request);
  return data;
};

export const loginByToken = async (email, token) => {
  const request = {
    user: {
      email,
      token,
    },
  };
  const axios = createInstanceNoToken();
  const { data } = await axios.post('loginToken', request);
  return data;
};

export const forgotPassword = async (email) => {
  const request = {
    email,
  };
  const axios = createInstanceNoToken();
  const { data } = await axios.post('/sendEmail', request);
  return data;
};

export const resetPassword = async (email, password, token) => {
  const request = {
    email,
    password,
  };
  const axios = createInstance({ token });
  const { data } = await axios.post('/resetPassword', request);
  return data;
};

export const loginByPhone = async (phone, field, company) => {
  const request = {
    user: {
      phone,
      field,
      company,
    },
  };
  const axios = createInstanceNoToken();
  const { data } = await axios.post('/loginPhone', request);
  return data;
};

export const logout = async () => {
  try {
    const axios = createInstance();
    const { data } = await axios.post('/logout');
    return { ...data };
  } catch (e) {
    return null;
  }
};
